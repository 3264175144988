<template>
  <div style="position: relative;" ref="rootDiv" >

    <!-- 사진캡처 버튼 -->
    <div ref="vodScreenShot" class="vodDown flex"  v-show="isPlayStatus && (isVod ? true : isLiveButtonShow )" :style="{'right' : captureRight + 'px'}" @click="onVideoCapture">
      <img src="@/assets/images/icon_svg/ic_screen_shot.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
    </div>
    <!-- 라이브 우측상단 전체화면 버튼 -->
    <div class="vodDown flex" v-if=" !isVod && isLiveButtonShow && isPlayStatus" :style="{'right' : expandRight + 'px'}" @click="onLiveExpand">
      <img src="@/assets/images/icon_svg/ic_full_bk.svg" style="width: 2rem; height: 2rem; margin-right: 2px;"/>
    </div>

    <!-- 라이브 우측하단 전체화면버튼 -->
    <div v-if="playerId != undefined && !isVod" @click="openFullScreenModal" class="right-bottom-fullscreen">
      <img src="@/assets/images/icon_svg/ic_full.svg" alt="icon"/>
    </div>
    <!-- 다운로드 버튼 -->
    <div ref="vodDownload" class="vodDown flex"  v-show="isVod && isDownload && isPlayStatus"  :style="{'right' : downloadRight + 'px'}" @click="onVideoDownload">
      <img src="@/assets/images/icon_svg/ic_download_vod.svg" style="width: 2rem; height: 2rem;"/>
    </div>
    <!-- 삭제 버튼 -->
    <div class="vodDown flex" v-if="isVod && isDeleteButtonShow && isDelete && isPlayStatus" :style="{'right' : deleteRight + 'px'}" @click="onVideoDelete">
      <img src="@/assets/images/icon_svg/ic_delete_vod.svg" style="width: 1.8rem; height: 1.8rem;"/>
    </div>

    <!-- 오른쪽 하단 스피커버튼 -->
    <!-- 드론은 소리x -->
    <div v-if="!isVod && deviceType !== 'Drone'" @click="isSoundToggle" class="soundBox" >
      <img class="speekerSize" v-if="!this.playerOptions.muted" src="@/assets/images/icon_svg/ic_speaker_on.png" alt="icon" />
      <img class="speekerSize"  v-else src="@/assets/images/icon_svg/ic_speaker_off.png" alt="icon" />
    </div>

    <!-- AI 선택 버튼 (유저 라이센스에 따라 열림) -->
    <div v-if="false" ref="aiSelect" v-show="( this.$router.currentRoute.name=='vodPlay' || isAiMarkShow) && isPlayStatus" class="vod-ai-mark" id="ai-mark-root">
      <div class="vod-ai-btn">
        <input type="radio" v-model="selectAiDetecing" name="detecting" value ="object">
        <label>객체 감지</label>
        <input type="radio" v-model="selectAiDetecing" name="detecting" value ="fire" >
        <label>불 감지</label>
        <input type="radio" v-model="selectAiDetecing" name="detecting" :value ="null" >
        <label style="margin-right: 0px;">감지 해제</label>
      </div>
    </div>


    <videoPlayer
        class="video-player-box vjs-big-play-centered"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        @loadeddata="onPlayerLoadeddata($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        @statechanged="playerStateChanged($event)"
        @ready="playerReadied($event)"
        @error="playerError($event)"
    >

    <!--timeUpodate는 현재 실-->
    </videoPlayer>
  </div>
</template>

<style>

.right-bottom-fullscreen {
  position: absolute;
  bottom:10px;
  right: 10px;
  z-index: 10;
  line-height: 28px;
}
.soundBox {
  position: absolute;
  bottom:10px;
  right: 40px;
  z-index: 10;
  line-height: 28px;
}


.speekerSize {
  width: 20px;
}

/*비디오 다운로드*/
.vodDown{
  width: 36px;
  height: 36px;
  text-align: center;
  justify-content: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background: white;
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
  padding: 6px;
  color:black;
  border: 1px solid white;
}
.vodDown:hover{
  background: #94d9ef;
}
.video-js .vjs-volume-bar{
  margin: 1.35em 0em 1.35em 0.45em;
}

.vjs-slider-horizontal .vjs-volume-level:before{
  top: -0.3rem !important;
}
.video-js .vjs-play-progress:before{
  top: 15% !important;
  transform: translate(-0%, -40%);
}

.vjs-duration, .vjs-current-time, .vjs-time-divider {
  display: block !important;
}

.vjs-big-play-button > span.vjs-icon-placeholder{
  font-size: 3rem !important;
}

.video-player, .vjs-icon-placeholder, .vjs-volume-bar, .vjs-progress-control,.vjs-control-bar, .vjs-menu-button {
  font-size: 1.2rem !important;
}

.vjs-time-control {
  min-width: 0rem !important;
  display: block !important;
  padding-left: 0.3rem !important;
  padding-right: 0.3rem !important;
}
.vjs-fullscreen > #info-live-root > img {
  width: 8rem;
}

.livePlay > img {
  width: 6rem;
}
.livePopMark > img{width: 45px;}
</style>

<script>
// Similarly, you can also introduce the plugin resource pack you want to use within the component
// import 'some-videojs-plugin'
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
import {videoPlayer} from "vue-video-player";
import { fetchVodDownloadUrl, fetchDeleteVod } from "@/api/contents";
import { fetchChannelCheckStream } from "@/api/channel";
import icLiveMark from "@/assets/images/icon_svg/ic_live_mark.svg";

import moment from "moment";

//아이콘 이미지
//import imgCapture from "@/assets/images/icon_svg/ic_screen_shot.svg";
//import vodDownload from "@/assets/images/icon_svg/ic_download_vod.svg";
//import liveFull from "@/assets/images/icon_svg/ic_full_bk.svg";
//import delVod from "@/assets/images/icon_svg/ic_delete_vod.svg";

function getLiveTitleShow(isVod, currentRouteName){
  let className = currentRouteName == "livePlay" ? "livePlay" : "";
  let root = `<div id="info-live-root" class="livePopMark ${className}" style="display: ${isVod ?'none':'block'};">
      <img src="${icLiveMark}"/>
    </div>`
  let parser = new DOMParser();
  let doc = parser.parseFromString(root, "text/html");
  return doc.getElementById("info-live-root");
}

export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    isVod: {
      type: Boolean,
      default: true,
    },
    isUser: {
      type: Boolean,
      default: true,
    },
    playerId: {
      type:String,
      default: null,
    },
    contentsId:{
      type:String,
      default: null,
    },
    videoType :{ //추후 ai디텍팅시에 디텍팅 타입을 받으면 된다.
      type : String,
      default : "orginal"
    },
    isAuto : {
      type : Boolean,
      default : false,
    },
    isControls : {
      type : Boolean,
      default : true,
    },
    isLiveButtonShow: {
      type : Boolean,
      default : false,
    },
    isDeleteButtonShow: {
      type : Boolean,
      default : true,
    },
    isAiMarkShow:{
      type : Boolean,
      default : false,
    },

    volumNumber: {
      type : Number,
      default: 0.5
    },
    deviceType: {
      type: String,
      default: null
    },
    // 현재 streamId(playerId)와, 선택한 streamId를 비교해서 소리를 들리게할지말지 결정
    selectStreamId: {
      type : String,
    },
    channelIndex: {
      type: Number,
      default: null
    },
    // 하단 소리와, 전체화면 마커 포지션을 변경하려고 만든변수...
    soundMarkerPosition: {
      type:Number,
      default: null
    }

    //isai-mark  샤용유무 값 받아서 표출할지말지 결정
    //ai this.$emit 을 사용해서 값 넘겨주기. (ai-object , ai-fire, ai-clear)
    //새창보기부분 표출(map, 현장정보) id값 받아서 플레이어 자식에 추가하기.
  },
  components: {
    videoPlayer,
  },
  data() {
    return {
      isLoadedmetadata : false,
      interval : null,
      liveLoadError : false,
      player: null,
      playerOptions: {
        // videojs options
        crossOrigin : "anonymous",
        muted: true,
        volume: this.volumNumber,
        autoplay: this.isAuto,
        language: "ko",
        playbackRates: [0.5, 1.0, 2.0, 4.0],
        sources: [
          {
            src: "",
            type: "application/x-mpegURL",
            withCredentials: false,
          },
        ],
        errorDisplay: false,
        controls: this.isControls,
        controlBar: {
          playToggle: true,
          volumePanel: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          progressControl: true,
          liveDisplay: true,
          seekToLive: true,
          //   customControlSpacer: true,
          playbackRateMenuButton: this.isVod,
          //   chaptersButton: true,
          //   descriptionsButton: true,
          //   subsCapsButton: true,
          audioTrackButton: true,
          fullscreenToggle: true,
        },
        hls: true,
        downloadFile: true,
        bigPlayButton: true,


        // flash: { hls: { withCredentials: false } },
        // html5: { hls: { withCredentials: false } },
        // poster: "/static/images/author.jpg",
      },

      isFullScreen : false,

      selectAiDetecing : null,
      isPlayStatus : false,
      errorCount : 0,
      oldCurrentTimeError : 0,
      oldCurrentTime : 0,

      buttonDefaultRight: 10,
      buttonMargin : 16,
      buttonSize : 32,

      isDelete : !this.isUser,
      isDownload : this.isVod,
      isExpand : !this.isVod,

      playerAddDom : null,
    };
  },
  computed : {
    captureRight: function () {
      var right = 0;
      if(this.isVod) {
        right = this.buttonDefaultRight;
        if(this.isDelete && this.isDeleteButtonShow) {
          right = right + this.buttonMargin + this.buttonSize;
        }
        if(this.isDownload) {
          right = right + this.buttonMargin + this.buttonSize;
        }

      } else {
        right = this.buttonDefaultRight;
        if(this.isExpand) {
          right = right + this.buttonMargin + this.buttonSize;
        }
      }

      if(this.isFullScreen) {
        right = 0;
      }

      if(right == 0){
        right = this.buttonDefaultRight;
      }
      return right;
    },
    deleteRight: function () {
      var right = 0;
      if(this.isDelete && this.isDeleteButtonShow) {
        right = this.buttonDefaultRight;
      }
      return right;
    },
    downloadRight: function () {
      var right = 0;
      if(this.isDelete && this.isDeleteButtonShow) {
        right = right + this.deleteRight;
        right = right + this.buttonMargin + this.buttonSize;
      }
      if(right == 0) {
        right = this.buttonDefaultRight;
      }
      return right;
    },
    expandRight: function () {
      var right = 0;
      if(this.isExpand) {
        right = this.buttonDefaultRight;
      }
      return right;
    }
  },
  mounted() {
    this.getVideo();
    this.interval = setInterval(() => {
      this.onPlayerCheckStatus();
    }, 3000);

    addEventListener("fullscreenchange", this.onfullscreenchange);
  },

  beforeDestroy() {
    if(this.interval != null) {
      clearInterval(this.interval);
    }
  },
  watch: {
    selectAiDetecing(value) {
      this.$emit("onUpdateAiDetecting",value);//현재 동영상 시간, contentId, aiDetecting type 넘기기.
    },
    isUser(value) {
      this.isDelete = !value;
    },
    src() {
      this.isPlayStatus = false;
      this.getVideo();
    },
    selectStreamId: function(newValue){
      if(this.playerId != newValue ) {
        this.mute()
      } else {
        this.unMute()
      }
    },
  },
  methods: {
    openFullScreenModal() {
      this.$emit('playerOpen',"live", this.playerId,this.channelIndex)
    },
    isSoundToggle(){
      this.playerOptions.muted = !this.playerOptions.muted
      this.$emit("toggleSound",this.playerId)
    },
    openPopup() {
      this.$emit('openPopup');
    },
    mute(){
      this.playerOptions.muted = true
    },
    unMute(){
      this.playerOptions.muted = false
    },
    onfullscreenchange() {
      this.isFullScreen = false;
      if(document.fullscreenElement != null) {
        this.isFullScreen = true;
      }
      if(this.playerAddDom != null) {
        if( this.isFullScreen == false) {
          this.$refs.rootDiv.appendChild(this.playerAddDom);
        } else {
          this.$refs.videoPlayer.player.el_.appendChild(this.playerAddDom);
        }
      }
    },
    onPlayerCheckStatus(){
      if(this.isVod == false){

        fetchChannelCheckStream(this.playerId).then((res) => {
          if (res.data.result == 0) {
            if(res.data.data.bytesIn == 0) {
              this.oldCurrentTimeError++;
            } else {
              this.oldCurrentTimeError = 0;
            }

            if( this.oldCurrentTimeError > 3){
              this.onPlayerRemove();
            }
          }
        })
      }
    },
    onPlayerReloadTimeout(){
      setTimeout(() => {
        this.reloadPlayer();
      }, 2000);
    },
    onVideoDownload(){
      if(this.contentsId != null) {
        if (!confirm(this.$t("alert-message-download-contents-player-message")) ) {
          return;
        }

        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function(){
          if (this.readyState == 4 && this.status == 200){
            var filename = "";

            var disposition = xhr.getResponseHeader('Content-Disposition');

            if (disposition && disposition.indexOf('attachment') !== -1) {
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }

            //this.response is what you're looking for
            var a = document.createElement("a");
            var url = URL.createObjectURL(this.response)
            a.href = url;
            if(filename == "") {
              filename = `${moment(Date()).format("YYYY-MM-DD-HH-mm-ss")}.mp4`;
            }
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        }
        xhr.open('GET', fetchVodDownloadUrl(this.contentsId));
        xhr.responseType = 'blob'; // !!필수!!
        xhr.setRequestHeader("x-token", localStorage.getItem("token"));
        xhr.setRequestHeader("Access-Control-Expose-Headers", "*");
        xhr.setRequestHeader( "Access-Control-Allow-Origin", "*");

        xhr.send(); // 파라미터 설정하는 부분이며 formData 설정 부분은 생략
      }

    },
    onVideoCapture(){
      // if (!confirm(this.$t("alert-message-download-contents-capture-message")) ) {
      //   return;
      // }

      let videoElement = this.$refs.videoPlayer.player.children_[0];
      let canvas = document.createElement("canvas");
      canvas.crossOrigin = "anonymous";
      let ctx = canvas.getContext('2d');

      canvas.width = 640;
      canvas.height = 360;

      ctx.fillRect(0,0,canvas.width,canvas.height);
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height );
      ctx.crossOrigin = "anonymous";

      var link = document.createElement('a');
      link.download = `${moment(Date()).format("YYYY-MM-DD-HH-mm-ss")}.png`;
      link.href = canvas.toDataURL("image/png");
      link.click();
    },
    onVideoDelete(){
      if (!confirm(this.$t("alert-message-delete-contents-message")) ) {
        return;
      }
      fetchDeleteVod(this.contentsId).then((res) => {
        if (res.data.result == 0) {
          this.$emit("contents-delete", this.contentsId)
        }
      });
    },
    onLiveExpand(){
      let player = this.$refs.videoPlayer.player;
      if(player.requestFullscreen){
        player.requestFullscreen();
      } else if(player.webkitRequestFullscreen){
        player.webkitRequestFullscreen();
      } else if(player.mozRequestFullscreen){
        player.mozRequestFullscreen();
      }
    },
    getVideo() {
      if(this.playerOptions.sources[0].src != this.src) {
        this.playerOptions.sources[0].src = this.src;
      }
    },

    // listen event
    onPlayerPlay(player) {
      player;
    },
    onPlayerTimeupdate(player) {
      this.$emit("onTimeUpdate", player.currentTime(),this.contentsId);
    },
    onPlayerPause(player) {
      player;
    },
    // ...player event
    onPlayerLoadeddata(player) {
      if(player.error == true) {
        this.isLoadedmetadata = false;
      } else {

        let infoLiveRoot = player.el_.querySelector('#info-live-root');

        if(infoLiveRoot == undefined || infoLiveRoot == null) {
          let liveHtml = getLiveTitleShow(this.isVod, this.$router.currentRoute.name);
          player.el_.appendChild(liveHtml);
        }
        if(this.isAiMarkShow || this.$router.currentRoute.name == 'vodPlay'){
          if(this.$refs.aiSelect != undefined) {
            player.el_.appendChild(this.$refs.aiSelect);
          }
        }
        if(this.$router.currentRoute.name == 'vodPlay' || this.$router.currentRoute.name == 'VOD' || this.$router.currentRoute.name == 'livePlay'){
          player.el_.appendChild(this.$refs.vodScreenShot);
        }
      }
    },
    onPlayerAddDom(addId) {
      //player에 dom 추가 함수
       this.playerAddDom = document.querySelector(`#${addId}`);
       if(this.playerAddDom != undefined) {
         this.$refs.rootDiv.appendChild(this.playerAddDom);
       }
    },
    onPlayerRemove(){
      this.$emit('playerRemoveId',this.playerId)
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      if(playerCurrentState.timeupdate == undefined) {
        if(playerCurrentState.canplay == true) {
          this.errorCount = 0;
          this.liveLoadError = false
          this.isLoadedmetadata = true;
          this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
        }

        if(this.isVod == false && playerCurrentState.pause == true) {
          this.isLoadedmetadata = false;
          this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
        }
        if(playerCurrentState.play != undefined || playerCurrentState.pause != undefined){
          this.isPlayStatus = true;
          this.$emit("isCanPlay", this.playerId, playerCurrentState);
        }
      }
    },
    reloadPlayer(){
      if(this.errorCount > 3) {
        this.onPlayerRemove();
      }

      if(this.liveLoadError == true && this.isVod == false) {
        this.errorCount++;
        this.playerOptions.sources[0].src = null;
        this.isPlayStatus = false;
        this.getVideo();
      }
    },
    playerError(player){
      switch (player.error_.code) {
        case 2 : {
          this.onPlayerRemove();
          break;
        }
        case 4 : {
          this.liveLoadError = true;
          this.isLoadedmetadata = false;
          this.onPlayerReloadTimeout();
          this.$emit("isLoadedmetadata", this.playerId, this.isLoadedmetadata);
          break;
        }
      }
    },
    // player is ready
    playerReadied(player) {
      player;
      this.$emit("isReadyPlay",player);

    },
  },
};
</script>
